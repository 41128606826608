import { StrictMode, useEffect } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from './redux/store';
import './index.css';

// styling components
import { message as messageAntd } from 'antd';

// routes
import routes from './routes';

// reducers actions
import { authUser } from './redux/reducers/auth';

const Root = () => {
	const dispatch = useDispatch();
	const { message, token, user } = useSelector(state => state.auth);
	const tokenLc = localStorage.getItem('ucpox-tkn');

	// notifation message handler
	useEffect(() => {
		if (message?.text) messageAntd[message.type](message.text);
	}, [message]);

	// authenticate user is token changes
	useEffect(() => {
		if (tokenLc && !token) dispatch(authUser(tokenLc));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenLc]);

	return (
		<Routes>
			{routes(user).map(props => {
				if (props.children)
					return (
						<Route key={props.path} {...props}>
							{props.children.map((childProps, idx) => {
								if (childProps.children)
									return (
										<Route key={idx} {...childProps}>
											{childProps.children.map((kidProps, i) => (
												<Route key={i} {...kidProps} />
											))}
										</Route>
									);

								return <Route key={idx} {...childProps} />;
							})}
						</Route>
					);

				return <Route key={props.path} {...props} />;
			})}
		</Routes>
	);
};

render(
	<StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<Root />
			</Provider>
		</BrowserRouter>
	</StrictMode>,
	document.getElementById('root')
);
