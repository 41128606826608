import { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Styling components
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

// Loader component
import Loader from '../../../components/loader';

// reducer action
import { signByCreds } from '../../../redux/reducers/auth';
import logo from '../../../assets/img/logo.png';

export default function AdminLogin() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, authenticated } = useSelector(state => state.auth);
	const [isLoading, setIsLoading] = useState(false);

	const onFinish = values => {
		setIsLoading(true);
		dispatch(signByCreds({ endpoint: 'admin-login', creds: values }));
		setIsLoading(false);
		if (authenticated) navigate('/', { replace: true });
	};

	if (!loading && !authenticated)
		return (
			<div style={{ padding: '0 2%' }}>
				<header style={{ display: 'flex', justifyContent: 'center' }}>
					<img src={logo} alt='logo' style={{ maxWidth: '100%' }} />
				</header>

				<Form
					name='login_form'
					wrapperCol={{ span: 24 }}
					onFinish={onFinish}
					style={{ maxWidth: 480, margin: 'auto' }}>
					<Form.Item
						name='email'
						rules={[{ required: true, message: 'Please enter your Admin Email!' }]}>
						<Input
							size='large'
							prefix={<UserOutlined className='site-form-item-icon' />}
							placeholder='Email'
						/>
					</Form.Item>

					<Form.Item
						name='password'
						rules={[{ required: true, message: 'Please enter your Admin Password!' }]}>
						<Input
							size='large'
							prefix={<LockOutlined className='site-form-item-icon' />}
							type='password'
							placeholder='Password'
						/>
					</Form.Item>

					<Form.Item>
						<Button type='primary' htmlType='submit' style={{ width: '100%' }}>
							{isLoading ? 'Signing in...' : 'Sign in'}
						</Button>
					</Form.Item>
				</Form>
			</div>
		);

	if (authenticated) return <Navigate to='/' replace />;

	return <Loader />;
}
