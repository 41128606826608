import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import { backendURL } from '../../config';

export default function Home() {
	const { user, token } = useSelector(state => state.auth);
	const [data, setData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (user.userName === 'Super Admin') {
					const {
						data: { poxes },
					} = await axios.get(`${backendURL}/api/v2/poxes`);
					const {
						data: { users },
					} = await axios.get(`${backendURL}/api/v2/users`, {
						headers: { Authorization: `Bearer ${token}` },
					});
					setData([
						{ label: 'Plots', url: 'poxes', amount: poxes.filter(e => e.type === 'Plot').length },
						{ label: 'Flats', url: 'poxes', amount: poxes.filter(e => e.type === 'Flat').length },
						{ label: 'Estabs', url: 'poxes', amount: poxes.filter(e => e.type === 'Estab').length },
						{ label: 'Reg. Users', url: 'users', amount: users.length },
						{ label: 'Online Users', url: 'user', amount: 0 },
					]);
				} else {
					const { data } = await axios.get(`${backendURL}/api/v2/poxes?publishedBy=${user._id}`);
					setData([
						{ label: 'Total Pox', url: 'poxes', amount: data.poxes.length },
						{
							label: 'Active Pox',
							url: 'poxes',
							amount: data.poxes.filter(e => e.status === 'active').length,
						},
						{
							label: 'Inactive Pox',
							url: 'poxes',
							amount: data.poxes.filter(e => e.status === 'inactive').length,
						},
						{
							label: 'My Pox',
							url: 'poxes/owned',
							amount: data.poxes.filter(e => e.ownership).length,
						},
					]);
				}
			} catch (error) {
				message.error(error?.response?.data?.message || error?.message);
			}
		};
		fetchData();

		return () => {
			setData([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='main-panel'>
			<div className='content'>
				<div className='container-fluid'>
					<h4 className='page-title'>Dashboard</h4>
					<div className='col col-lg-12'>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-evenly',
								gap: 30,
								flexWrap: 'wrap',
							}}>
							{data &&
								data.map(({ label, url, amount }, idx) => (
									<Link
										to={url}
										key={idx}
										style={{
											width: 125,
											height: 125,
											border: 'thin solid',
											borderRadius: '50%',
											textAlign: 'center',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											padding: 5,
										}}>
										<h4 style={{ fontSize: '1.25em', whiteSpace: 'wrap' }}>{label}</h4>
										<span style={{ fontSize: '2em', fontWeight: '700' }}>{amount}</span>
									</Link>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
