import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Divider, message } from "antd";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import MapPicker from "react-google-map-picker";
import { backendURL, mapAPIKEY } from "../../../config";

export default function PoxInfo() {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [data, setData] = useState(null);
  const [zoom, setZoom] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${backendURL}/api/v2/poxes/${id}`);
        setData(res.data.pox);
      } catch (error) {
        message.error(error?.response?.data?.message || error.message);
      }
    };
    fetchData();

    return () => {
      setData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
          <div style={{ display: "flex", gap: 10, marginBottom: 20 }}>
            <Button
              type="default"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate("/poxes", { replace: true })}
            >
              Back
            </Button>
            <h4 className="page-title" style={{ flexGrow: 1 }}>
              {data?.name}
            </h4>
            {user.userName !== "Super Admin" && (
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => navigate(`/poxes/${id}/edit`)}
              ></Button>
            )}
          </div>
          <div className="col col-lg-12">
            <div className="row">
              <div className="col col-lg-5">
                <h5>Publisher's Info</h5>
                <p>Full name: {data?.publisher_name}</p>
                <p>Email: {data?.publisher_email}</p>
                <Divider />
                <h5>Cost</h5>
                <p>
                  Deposit: {data?.currency[1] + data?.deposit.toLocaleString()}
                </p>
                <p>Rent: {data?.currency[1] + data?.rent.toLocaleString()}</p>
                <p style={{ fontWeight: 700 }}>
                  Total:{" "}
                  {data?.currency[1] +
                    (data?.deposit + data?.rent).toLocaleString()}
                </p>
                <Divider />
                <h5>Property's Info</h5>
                <p>Property Type: {data?.type}</p>
                <p>Sale type: {data?.sale_type}</p>
                <p>Room type: {data?.room_config}</p>
                <p>Plot type: {data?.plot_config}</p>
                <p>Area: {data?.area}</p>
                <p>Direction: {data?.facing}</p>
                <p>Number of floors: {data?.floor}</p>
                <p>Description: {data?.description}</p>
              </div>
              <div className="col col-lg-7">
                <MapPicker
                  defaultLocation={data?.position}
                  zoom={zoom}
                  mapTypeId="roadmap"
                  style={{ height: "500px" }}
                  onChangeZoom={(newZoom) => setZoom(newZoom)}
                  // onChangeLocation={() => null}
                  apiKey={mapAPIKEY}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
