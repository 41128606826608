import menuItems from './menu-items';
import CustomNavLink from '../../../../components/nav-link';
import defaultImg from '../../../../assets/img/profile.jpg';

export default function Sidebar({ user }) {
	const name = user?.userName || user?.profile?.user_name;
	const src = user?.imageUrl || user?.profile?.imageUrl || defaultImg;
	const level = user?.userName ? 'ADMIN' : 'USER';

	return (
		<div className='sidebar'>
			<div className='scrollbar-inner sidebar-wrapper'>
				<div className='user'>
					<div className='photo'>
						<img src={src} alt={name} />
					</div>
					<div className='info'>
						<span className='case'>
							<span>
								{name}
								<span className='user-level'>{level}</span>
							</span>
						</span>
					</div>
				</div>
				<ul className='nav'>
					{menuItems(user).map(menu => (
						<CustomNavLink key={menu.url} {...menu} />
					))}
				</ul>
			</div>
		</div>
	);
}
