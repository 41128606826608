export default function Settings() {
	return (
		<div className='main-panel'>
			<div className='content'>
				<div className='container-fluid'>
					<h4 className='page-title'>Settings</h4>
					<div className='col col-lg-12'>
						<div className='row'></div>
					</div>
				</div>
			</div>
		</div>
	);
}
