import { 
    MapContainer, TileLayer, 
    useMapEvents
} from 'react-leaflet';

import 'leaflet/dist/leaflet.css'

function LocationMarker({ setLocation }) {
    const map = useMapEvents({
      click(e) {
        setLocation(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
      }
    })
  
    return null;
}

const LeafletMapPicker = ({ location, setLocation }) => {
    return (
        <MapContainer
            zoom={location.lat !== 10 ? [15] : [5]}
            center={[location.lat, location.lng]}
            id="map"
            style={{height:"100%", width:"100%"}}
        >
            <TileLayer
                attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            />

            <LocationMarker setLocation={setLocation} />
        </MapContainer>
    )
}

export default LeafletMapPicker;