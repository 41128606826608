import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Space, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { backendURL } from "../../../config";

export default function Poxes() {
  const navigate = useNavigate();
  const { user, token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res =
        user.userName === "Super Admin"
          ? await axios.get(`${backendURL}/api/v2/poxes`)
          : await axios.get(
              `${backendURL}/api/v2/poxes?publishedBy=${user._id}`
            );
      setData(
        res.data.poxes.map((e) => ({
          key: e._id,
          name: e.name,
          type: e.type,
          date: e.createdAt,
          userId: e.publishedBy,
        }))
      );
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      setData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // delete location
  const deletePox = async (token, id) => {
    try {
      const res = await axios.delete(`${backendURL}/api/v2/poxes/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res;
    } catch (err) {
      message.error(err?.response?.data?.message || err?.message);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this location"))
      return;
    const res = await deletePox(token, id);
    message.success(res?.data?.message);
    await fetchData();
  };

  const columns = [
    {
      title: "Pox Id",
      dataIndex: "key",
      key: "key",
      render: (text) => text.slice(0, 10) + "...",
    },
    {
      title: "Pox Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Pox Type",
      dataIndex: "type",
      filters: [
        {
          text: "Plot",
          value: "Plot",
        },
        {
          text: "Flat",
          value: "Flat",
        },
        {
          text: "Estab",
          value: "Estab",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
    {
      title: "Date Published",
      dataIndex: "date",
      key: "date",
      render: (text) =>
        new Date(text).toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
    },
    // {
    // 	title: 'UserId',
    // 	dataIndex: 'userId',
    // 	key: 'userId',
    // 	render: text => text.slice(0, 10) + '...',
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Link to={record.key}>View</Link>
          <Button type="danger" onClick={() => handleDelete(record.key)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 className="page-title">Poxes</h4>
            {user.userName !== "Super Admin" && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate("/poxes/new")}
              >
                Add New
              </Button>
            )}
          </div>
          <div className="col col-lg-12">
            <Table columns={columns} dataSource={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
