import { useState } from 'react';
import MapPicker from 'react-google-map-picker';
import { mapAPIKEY } from '../config';

const LocationPicker = ({ location, setLocation }) => {
	const [zoom, setZoom] = useState(10);

	const handleChangeLocation = (lat, lng) => setLocation({ lat: lat, lng: lng });
	const handleChangeZoom = newZoom => setZoom(newZoom);

	return (
		<MapPicker
			defaultLocation={location}
			zoom={zoom}
			mapTypeId='roadmap'
			style={{ height: '500px' }}
			onChangeLocation={handleChangeLocation}
			onChangeZoom={handleChangeZoom}
			apiKey={mapAPIKEY}
		/>
	);
};

export default LocationPicker;
