import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import logo from '../../../../assets/img/logo-trimmed.png';
import defaultImg from '../../../../assets/img/profile.jpg';
import profile2 from '../../../../assets/img/profile2.jpg';

export default function Header({ user, logoutHandler, handleMobSideNav, handleMobTopNav }) {
	const name = user?.userName || user?.profile?.user_name;
	const src = user?.imageUrl || user?.profile?.imageUrl || defaultImg;
	const email = user?.email || user?.profile?.email;

	return (
		<div className='main-header'>
			<div className='logo-header'>
				<Link to='/'>
					<img src={logo} alt='ucpox' className='logo' />
				</Link>
				<button
					className='navbar-toggler sidenav-toggler ml-auto'
					type='button'
					data-toggle='collapse'
					data-target='collapse'
					aria-controls='sidebar'
					aria-expanded='false'
					aria-label='Toggle navigation'
					onClick={handleMobSideNav}>
					<span className='navbar-toggler-icon'></span>
				</button>
				<button className='topbar-toggler more' onClick={handleMobTopNav}>
					<i className='la la-ellipsis-v'></i>
				</button>
			</div>
			<nav className='navbar navbar-header navbar-expand-lg'>
				<div className='container-fluid'>
					{/* uncomment when ready to setup functionalities */}
					{/* <form className='navbar-left navbar-form nav-search mr-md-3' action=''>
						<div className='input-group'>
							<input type='text' placeholder='Search ...' className='form-control' />
							<div className='input-group-append'>
								<span className='input-group-text'>
									<i className='la la-search search-icon'></i>
								</span>
							</div>
						</div>
					</form> */}
					<ul className='navbar-nav topbar-nav ml-md-auto align-items-center'>
						<li className='nav-item dropdown hidden-caret'>
							<a
								className='nav-link dropdown-toggle'
								href='/'
								id='navbarDropdown'
								role='button'
								data-toggle='dropdown'
								aria-haspopup='true'
								aria-expanded='false'>
								<Tooltip title='messages'>
									<i className='la la-envelope'></i>
								</Tooltip>
							</a>
							<div className='dropdown-menu' aria-labelledby='navbarDropdown'>
								<a className='dropdown-item' href='/'>
									Coming soon
								</a>
								<a className='dropdown-item' href='/'>
									Coming soon
								</a>
								<div className='dropdown-divider'></div>
								<a className='dropdown-item' href='/'>
									Coming soon
								</a>
							</div>
						</li>
						<li className='nav-item dropdown hidden-caret'>
							<a
								className='nav-link dropdown-toggle'
								href='/'
								id='navbarDropdown'
								role='button'
								data-toggle='dropdown'
								aria-haspopup='true'
								aria-expanded='false'>
								<Tooltip title='notifications'>
									<i className='la la-bell'></i>
								</Tooltip>
								<span className='notification'>3</span>
							</a>
							<ul className='dropdown-menu notif-box' aria-labelledby='navbarDropdown'>
								<li>
									<div className='dropdown-title'>You have 3 new notification</div>
								</li>
								<li>
									<div className='notif-center'>
										<a href='/'>
											<div className='notif-icon notif-primary'>
												<i className='la la-user-plus'></i>
											</div>
											<div className='notif-content'>
												<span className='block'>New user registered</span>
												<span className='time'>5 minutes ago</span>
											</div>
										</a>
										<a href='/'>
											<div className='notif-icon notif-success'>
												<i className='la la-comment'></i>
											</div>
											<div className='notif-content'>
												<span className='block'>*** commented on Admin</span>
												<span className='time'>12 minutes ago</span>
											</div>
										</a>
										<a href='/'>
											<div className='notif-img'>
												<img src={profile2} alt='random username' />
											</div>
											<div className='notif-content'>
												<span className='block'>*** send messages to you</span>
												<span className='time'>12 minutes ago</span>
											</div>
										</a>
										<a href='/'>
											<div className='notif-icon notif-danger'>
												<i className='la la-heart'></i>
											</div>
											<div className='notif-content'>
												<span className='block'>*** liked Admin</span>
												<span className='time'>17 minutes ago</span>
											</div>
										</a>
									</div>
								</li>
								<li>
									<a className='see-all' href='/'>
										<strong>See all notifications</strong> <i className='la la-angle-right'></i>
									</a>
								</li>
							</ul>
						</li>
						<li className='nav-item dropdown'>
							<a
								className='dropdown-toggle profile-pic'
								data-toggle='dropdown'
								href='/'
								aria-expanded='false'>
								<img src={src} alt={name} width='36' className='img-circle mr-1' />
								<span>{name}</span>
							</a>
							<ul className='dropdown-menu dropdown-user'>
								<li>
									<div className='user-box'>
										<div className='u-img'>
											<img src={src} alt={name} />
										</div>
										<div className='u-text'>
											<p className='text-muted'>{email}</p>
											<a href='profile.html' className='btn btn-rounded btn-danger btn-sm'>
												View Profile
											</a>
										</div>
									</div>
								</li>
								<div className='dropdown-divider'></div>
								<a className='dropdown-item' href='/'>
									<i className='ti-user'></i> My Profile
								</a>
								<a className='dropdown-item' href='/'>
									My Balance
								</a>
								<a className='dropdown-item' href='/'>
									<i className='ti-email'></i> Inbox
								</a>
								<div className='dropdown-divider'></div>
								<a className='dropdown-item' href='/'>
									<i className='ti-settings'></i> Account Setting
								</a>
								<div className='dropdown-divider'></div>
								<button className='dropdown-item' onClick={logoutHandler}>
									<i className='fa fa-power-off'></i> Logout
								</button>
							</ul>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	);
}
