import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendURL } from '../../config';

export const authUser = createAsyncThunk(
	'auth/authenticateUser',
	async (token, { rejectWithValue }) => {
		try {
			const res = await axios.get(`${backendURL}/api/v2/auth`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			return res.data;
		} catch (err) {
			if (!err.response) throw err;
			return rejectWithValue(err.response.data);
		}
	}
);

export const signByCreds = createAsyncThunk(
	'auth/signByCredentials',
	async ({ endpoint, creds }, { rejectWithValue }) => {
		try {
			const res = await axios.post(`${backendURL}/api/v2/${endpoint}`, creds);
			return res.data;
		} catch (err) {
			if (!err.response) throw err;
			return rejectWithValue(err.response.data);
		}
	}
);

const initialState = {
	loading: false,
	authenticated: false,
	user: null,
	token: null,
	message: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: state => {
			localStorage.removeItem('ucpox-tkn');
			state.loading = false;
			state.authenticated = false;
			state.user = null;
			state.token = null;
			state.message = null;
		},
		updateUserProfile: (state, action) => {
			state.user = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(signByCreds.pending, state => {
			state.loading = true;
			state.message = null;
		});
		builder.addCase(signByCreds.fulfilled, (state, action) => {
			state.loading = false;
			state.authenticated = true;
			state.user = action.payload.user;
			state.token = action.payload.token;
			state.message = { text: action.payload.message, type: 'success' };
			localStorage.setItem('ucpox-tkn', action.payload.token);
		});
		builder.addCase(signByCreds.rejected, (state, action) => {
			state.loading = false;
			state.authenticated = false;
			state.user = null;
			state.token = null;
			state.message = { text: action.payload?.message || action.error.message, type: 'error' };
		});
		builder.addCase(authUser.pending, state => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(authUser.fulfilled, (state, action) => {
			state.loading = false;
			state.authenticated = true;
			state.user = action.payload.user;
			state.token = localStorage.getItem('ucpox-tkn');
			state.message = { text: action.payload.message, type: 'success' };
		});
		builder.addCase(authUser.rejected, (state, action) => {
			state.loading = false;
			state.authenticated = false;
			state.user = null;
			state.token = null;
			state.message = { text: action.payload?.message || action.error.message, type: 'error' };
			localStorage.removeItem('ucpox-tkn');
		});
	},
});

export const { logout, updateUserProfile } = authSlice.actions;

export default authSlice.reducer;
