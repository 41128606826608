// layout
import Layout from '../pages/dashboard/layout';

// routes
import Home from '../pages/dashboard/home';
import Settings from '../pages/dashboard/settings';

// users
import UsersDefault from '../pages/dashboard/users/default';
import UserInfo from '../pages/dashboard/users/info';

// poxes
import PoxDefault from '../pages/dashboard/poxes/default';
import PoxInfo from '../pages/dashboard/poxes/info';
import PoxNew from '../pages/dashboard/poxes/new';
import PoxEdit from '../pages/dashboard/poxes/edit';
import MyPoxes from '../pages/dashboard/poxes/owned';
import ReportedPoxes from '../pages/dashboard/poxes/reported';

// general pages
import GoogleOauth from '../pages/authentication/user/google-oauth';
import AdminLogin from '../pages/authentication/admin/login';
import NotFound from '../pages/404';

const generalRoutes = [
	{ path: 'login', element: <GoogleOauth /> },
	{ path: 'admin', element: <AdminLogin /> },
	{ path: '*', element: <NotFound /> },
];

const adminRoutes = {
	path: '/',
	element: <Layout />,
	children: [
		{ index: true, element: <Home /> },
		{
			path: 'users',
			children: [
				{ index: true, element: <UsersDefault /> },
				{ path: ':id', element: <UserInfo /> },
			],
		},
		{
			path: 'poxes',
			children: [
				{ index: true, element: <PoxDefault /> },
				{ path: 'reported', element: <ReportedPoxes /> },
				{ path: ':id', element: <PoxInfo /> },
			],
		},
		{ path: 'settings', element: <Settings /> },
	],
};

const userRoutes = {
	path: '/',
	element: <Layout />,
	children: [
		{ index: true, element: <Home /> },
		{
			path: 'poxes',
			children: [
				{ index: true, element: <PoxDefault /> },
				{ path: 'new', element: <PoxNew /> },
				{ path: 'owned', element: <MyPoxes /> },
				{ path: ':id', element: <PoxInfo /> },
				{ path: ':id/edit', element: <PoxEdit /> },
			],
		},
		{ path: 'settings', element: <Settings /> },
	],
};

export default function routes(user) {
	if (user?.userName === 'Super Admin') return [adminRoutes, ...generalRoutes];

	return [userRoutes, ...generalRoutes];
}
