import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GoogleLogin from 'react-google-login';

// Styling components
import { message } from 'antd';
import './style.css'

// Loader component
import Loader from '../../../components/loader';

// reducer action
import { signByCreds } from '../../../redux/reducers/auth';
import logo from '../../../assets/img/logo.png';
import globe from '../../../assets/img/globe.png';

import { googleClientID } from '../../../config';

export default function GoogleOauth() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, authenticated } = useSelector(state => state.auth);

	const handleSuccess = async response => {
		dispatch(signByCreds({ endpoint: 'google-auth', creds: { profileObj: response.profileObj } }));
		if (authenticated) navigate('/', { replace: true });
	};

	const handleFailure = async response => {
		console.log(response);
		message.error('There is a problem, please try again later');
	};

	if (!loading && !authenticated)
		return (
			<div className="section-auth">
				<div className='section-logo'>
					<header style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={logo} alt='logo' />
					</header>
				</div>

				<div className='section-forms'>
					<div className="view-img">
						<img src={globe} alt="Globe Img" />
					</div>

					<div className=''>
						<div className='header-text'>
							Welcome to UCPOX
						</div>

						<div className='text'>Create an Account</div>
						<div className='login-btn'>
							<GoogleLogin
								clientId={googleClientID}
								onSuccess={handleSuccess}
								onFailure={handleFailure}
								cookiePolicy={'single_host_origin'}
							/>
						</div>

						<div className='divider'>
							<hr></hr>
							<span>Or</span>
							<hr></hr>
						</div>

						<div className="form-section">
							<div className='flex-group'>
								<div className='input-group'>
									<span>+91</span>
									<input 
										type="text" 
										name="phone_number"
										id="phone_number"
									/>
								</div>

								<button className='btn-submit'>Send OTP</button>
							</div>

							<div className='flex-group'>
								<div className='input-group input-form'>
									<input 
										type="text" 
										name="otp" 
										id="otp" 
										placeholder='Enter OTP'
									/>
								</div>

								<button className='btn-submit btn-outline'>Resend OTP</button>
							</div>

							<div className='submit-section'>
								<button className='btn-submit btn-lg'>Sign In</button>
							</div>
						</div>

					</div>
					
				</div>

			</div>
		);

	if (authenticated) return <Navigate to='/' replace />;

	return <Loader />;
}
