import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table, message } from 'antd';
import { backendURL } from '../../../config';

export default function UserList() {
	const { token } = useSelector(state => state.auth);
	const [users, setUsers] = useState([]);

	const fetchData = async () => {
		try {
			const res = await axios.get(`${backendURL}/api/v2/users`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			setUsers(
				await Promise.all(
					res.data.users.map(async ({ profile, _id, email, createdAt }) => ({
						key: _id,
						userName: profile.user_name,
						email,
						poxes: (
							await axios.get(`${backendURL}/api/v2/poxes??publishedBy=${_id}`)
						).data?.poxes.length,
						status: 'Active',
						since: new Date(createdAt).toLocaleDateString('en-us', {
							year: 'numeric',
							month: 'short',
						}),
					}))
				)
			);
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message);
		}
	};

	useEffect(() => {
		fetchData();

		return () => {
			setUsers([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = [
		{
			title: 'User Id',
			dataIndex: 'key',
			key: 'key',
			render: text => text.slice(0, 10) + '...',
		},
		{
			// user_name
			title: 'User Name',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			// email
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			// since
			title: 'Since',
			dataIndex: 'since',
			key: 'since',
		},
		{
			// poxes
			title: 'Poxes',
			dataIndex: 'poxes',
			key: 'poxes',
		},
		{
			// status
			title: 'Status',
			dataIndex: 'status',
			filters: [
				{
					text: 'Active',
					value: 'Active',
				},
				{
					text: 'Inactive',
					value: 'Inactive',
				},
				{
					text: 'Blocked',
					value: 'Blocked',
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => <Link to={record.key}>View</Link>,
		},
	];

	return (
		<div className='main-panel'>
			<div className='content'>
				<div className='container-fluid'>
					<h4 className='page-title'>Users</h4>
					<div className='col col-lg-12'>
						<Table columns={columns} dataSource={users} />
					</div>
				</div>
			</div>
		</div>
	);
}
