import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Form, Input, InputNumber, Select, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { backendURL } from '../../../config';
import LeafletMapPicker from '../../../components/location-picker-leaflet';

export default function NewPox() {
	const navigate = useNavigate();
	const token = useSelector(state => state.auth.token);
	const [location, setLocation] = useState({ lat: 12.986161746068149,  lng: 77.59182723086195 });

	const handleCancel = () => {
		const res = window.confirm('Are you sure you want to leave this page? Changes would be lost');
		if (res) return navigate('/poxes', { replace: true });
	};

	const handleOnFinish = async value => {
		const bodyData = {
			name: value.name,
			type: value.type,
			description: value.description,
			facing: value.facing,
			floor: value.floor,
			area: value.area,
			room_config: value.room_config,
			plot_config: value.plot_config,
			sale_type: value.sale_type,
			position: location,
			rent: value.rent,
			deposit: value.deposit,
			turnover: value.turnover,
			visible: value.visible,
		};
		try {
			const res = await axios.post(`${backendURL}/api/v2/poxes`, bodyData, {
				headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
			});
			message.success(res.data.message);
			navigate('/poxes', { replace: true });
		} catch (error) {
			message.error(error?.response?.data.message || error.message);
		}
	};

	return (
		<div className='main-panel'>
			<div className='content'>
				<div className='container-fluid'>
					<h4 className='page-title'>New Pox</h4>
					<div className='col col-lg-12'>
						<Button type='danger' icon={<CloseOutlined />} onClick={handleCancel}>
							Cancel
						</Button>
						<div className='row' style={{ paddingTop: 20 }}>
							<div className='col col-lg-5'>
								<Form
									labelCol={{ span: 8 }}
									wrapperCol={{ span: 20 }}
									layout='horizontal'
									initialValues={{
										visible: 'Public',
										floor: 0,
										room_config: '1BHK',
										plot_config: 'Residential',
										sale_type: 'New',
										area: 0.0,
									}}
									onFinish={handleOnFinish}>
									<Form.Item
										label='Property Name'
										name='name'
										rules={[{ required: true, message: 'Please input name of the property' }]}>
										<Input />
									</Form.Item>
									<Form.Item
										label='Property Type'
										name='type'
										rules={[{ required: true, message: 'Please select property type' }]}>
										<Select>
											<Select.Option value='Plot'>Plot</Select.Option>
											<Select.Option value='Flat'>Flat</Select.Option>
											<Select.Option value='Estab'>Establishment</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item
										label='Rent Cost'
										name='rent'
										rules={[
											{
												required: true,
												message: 'Please input rent cost of the property',
											},
											{
												type: 'number',
												min: 1,
											},
										]}>
										<InputNumber />
									</Form.Item>
									<Form.Item
										label='Deposit Cost'
										name='deposit'
										rules={[
											{
												required: true,
												message: 'Please input deposit cost of the property',
											},
											{
												type: 'number',
												min: 1,
											},
										]}>
										<InputNumber />
									</Form.Item>
									<Form.Item
										label='Turnover'
										name='turnover'
										rules={[
											{
												required: true,
												message: 'Please input turnover of the property',
											},
											{
												type: 'number',
												min: 0,
											},
										]}>
										<InputNumber />
									</Form.Item>
									<Form.Item label='Visible To' name='visible'>
										<Select>
											<Select.Option value='Public'>Public</Select.Option>
											<Select.Option value='Private'>Private</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item label='Room Config' name='room_config'>
										<Select>
											<Select.Option value='1BHK'>1BHK</Select.Option>
											<Select.Option value='2BHK'>2BHK</Select.Option>
											<Select.Option value='2BHK+'>2BHK+</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item label='Plot Config' name='plot_config'>
										<Select>
											<Select.Option value='Agricultural'>Agricultural</Select.Option>
											<Select.Option value='Residential'>Residential</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item label='Sale Type' name='sale_type'>
										<Select>
											<Select.Option value='New'>New</Select.Option>
											<Select.Option value='Resale'>Resale</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item label='Floor Number' name='floor' rules={[{ type: 'number', min: 0 }]}>
										<InputNumber />
									</Form.Item>
									<Form.Item label='Area' name='area' rules={[{ type: 'number', min: 0.0 }]}>
										<InputNumber />
									</Form.Item>
									<Form.Item
										label='Entrance Dir.'
										name='facing'
										rules={[{ required: true, message: 'Please provide entrance direction' }]}>
										<Select>
											<Select.Option value='North'>North</Select.Option>
											<Select.Option value='North-East'>North East</Select.Option>
											<Select.Option value='East'>East</Select.Option>
											<Select.Option value='South-East'>South East</Select.Option>
											<Select.Option value='South'>South</Select.Option>
											<Select.Option value='South-West'>South West</Select.Option>
											<Select.Option value='West'>West</Select.Option>
											<Select.Option value='North-West'>North West</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item
										label='Description'
										name='description'
										rules={[{ required: true, message: 'Please describe the property in detail' }]}>
										<Input.TextArea />
									</Form.Item>

									<Button type='primary' htmlType='submit'>
										Submit
									</Button>
								</Form>
							</div>
							<div className='col col-lg-7'>
								{/* <h5>Pick location</h5> */}
								{/* <LocationPicker location={location} setLocation={setLocation} /> */}
								{/* <LocationPickerMap location={location} setLocation={setLocation} /> */}
								<LeafletMapPicker location={location} setLocation={setLocation} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
