import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LayoutWrapper from './main';

export default function Layout() {
	const { authenticated } = useSelector(state => state.auth);

	if (authenticated)
		return (
			<LayoutWrapper>
				<Outlet />
			</LayoutWrapper>
		);

	return <Navigate to='/login' />;
}
