import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Divider, Button, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { backendURL } from '../../../config';

export default function UserInfo() {
	const { id } = useParams();
	const navigate = useNavigate();
	const { token } = useSelector(state => state.auth);
	const [data, setData] = useState(null);

	const fetchData = async () => {
		try {
			const res = await axios.get(`${backendURL}/api/v2/users/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			setData(res.data.user);
		} catch (error) {
			message.error(error?.response?.data?.message || error.message);
		}
	};

	useEffect(() => {
		fetchData();

		return () => {
			setData(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='main-panel'>
			<div className='content'>
				<div className='container-fluid'>
					<div className='col col-lg-12'>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Button
								type='default'
								icon={<ArrowLeftOutlined />}
								onClick={() => navigate('/poxes', { replace: true })}>
								Back
							</Button>
							<h4 className='page-title'>User {data?.profile?.user_name}</h4>
						</div>
						<div className='row'>
							<div className='col col-lg-5'>
								<p>First name: {data?.profile?.first_name}</p>
								<p>Last name: {data?.profile?.last_name}</p>
								<p>User name: {data?.profile?.user_name}</p>
								<p>Email: {data?.email}</p>
								<Divider />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
