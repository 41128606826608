const userMenu = [
	{ label: 'Dashboard', iconClass: 'la la-dashboard', url: '/' },
	{ label: 'Poxes', iconClass: 'la la-plus p-1', url: '/poxes' },
	{ label: 'My Poxes', iconClass: 'la la-plus p-1', url: '/poxes/owned' },
	{ label: 'Settings', iconClass: 'la la-plus p-1', url: '/settings' },
];

const adminMenu = [
	{ label: 'Dashboard', iconClass: 'la la-dashboard', url: '/' },
	{ label: 'Users', iconClass: 'la la-plus p-1', url: '/users' },
	{ label: 'Poxes', iconClass: 'la la-plus p-1', url: '/poxes' },
	{ label: 'Settings', iconClass: 'la la-plus p-1', url: '/settings' },
];

export default function MenuItems(user) {
	if (user.userName === 'Super Admin') return adminMenu;

	return userMenu;
}
