import { Link, useResolvedPath, useMatch } from 'react-router-dom';

export default function CustomNavLink({ url, iconClass, label }) {
	const resolved = useResolvedPath(url);
	const match = useMatch({ path: resolved.pathname, end: true });

	return (
		<li className={match ? 'nav-item active' : 'nav-item'}>
			<Link to={url}>
				<i className={iconClass}></i>
				<p>{label}</p>
			</Link>
		</li>
	);
}
