import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useGoogleLogout } from 'react-google-login';
import { message } from 'antd';

import Header from './header';
import Sidebar from './sidebar';

import { logout } from '../../../redux/reducers/auth';
import { googleClientID } from '../../../config';

export default function Wrapper({ children }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(state => state.auth.user);
	const { signOut } = useGoogleLogout({
		clientId: { googleClientID },
	});

	const [navState, setNavState] = useState({ side: false, top: false });

	const handleMobSideNav = () => setNavState(prev => ({ ...prev, side: !navState.side }));
	const handleMobTopNav = () => setNavState(prev => ({ ...prev, top: !navState.top }));

	const logoutHandler = () => {
		dispatch(logout());
		message.success('Logged out');
		signOut();
		navigate('/login', { replace: true });
	};

	const headerProps = {
		user,
		handleMobSideNav,
		handleMobTopNav,
		logoutHandler,
	};

	return (
		<div className='wrapper'>
			<div
				className={
					navState.side && navState.top
						? 'nav_open topbar_open'
						: navState.side
						? 'nav_open'
						: navState.top
						? 'topbar_open'
						: ''
				}>
				<Header {...headerProps} />
				<Sidebar user={user} />
			</div>

			{children}
		</div>
	);
}
